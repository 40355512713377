import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useMutation } from "react-query"
import CancelDockwaPlusMembershipModal from "src/main/Account/Settings/CancelDockwaPlusMembershipModal"
import {
  DockwaPlusProvider,
  useDockwaPlusContext,
} from "src/main/Account/Settings/DockwaPlusContext"
import { DockwaPlusMembershipCard } from "src/main/Account/Settings/DockwaPlusMembershipCard"
import DockwaPlusMembershipSignupModal from "src/main/Account/Settings/DockwaPlusMembershipSignupModal"
import { SettingsContext } from "src/main/Account/Settings/SettingsContext"

import Badge from "src/components/Badge"
import Button from "src/components/Button"
import HeadingModalTitle from "src/components/Heading/HeadingModalTitle"

import { restoreDockwaPlusMembership } from "src/api/Account/Settings"

import { useLocalStorageState } from "src/hooks/use_local_storage_state"
import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

import { formattedCentsToDollars } from "src/utils/UnitConversion"
import { capitalize } from "src/utils/string_helpers"

const DockwaPlusMembershipContent = ({ user, setUser }) => {
  const { paymentMethods, setPaymentMethods } = useContext(SettingsContext)
  const tracker = useTracker()
  const [membershipPageVisited, setMembershipPageVisited] =
    useLocalStorageState("account", "membershipPageVisited")
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false)

  useEffect(() => {
    setMembershipPageVisited((parseInt(membershipPageVisited) || 0) + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showToast = useToast()
  const { productInfo } = useDockwaPlusContext()
  const { data: dockwaPlusProductInformation } = productInfo

  const { mutate: restoreMembership, isLoading: restoringMembership } =
    useMutation(() => restoreDockwaPlusMembership(), {
      onSuccess: (data) => {
        showToast(
          `You&apos;ve restored your Dockwa+ membership. You&apos;ll retain access to your benefits through ${data.subscription?.readableCurrentPeriodEnd}`,
          { type: "success" }
        )
        setUser(data.user)
        tracker.trackEvent("dockwa_plus:membership_restored")
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
        tracker.trackEvent("dockwa_plus:membership_restore_failed", {
          error: error.message,
        })
      },
    })

  const getSubtotalInDollars = () => {
    if (!dockwaPlusProductInformation?.line_items) return null

    const subTotal = dockwaPlusProductInformation.line_items.find(
      (item) => item.type === "subtotal"
    )

    if (subTotal) {
      return formattedCentsToDollars(subTotal.amount)
    }
  }

  const handleDockwaPlusSignup = () => {
    setIsSignupModalOpen(true)
    const { dockwa_plus_external_id: dockwaPlusExternalId } =
      dockwaPlusProductInformation

    tracker.trackEvent("dockwa_plus:checkout_screen_viewed", {
      dockwa_plus_external_id: dockwaPlusExternalId,
    })
  }

  const renderBlankState = () => (
    <div className="flex flex-col space-y-6">
      <span>
        Unlock exclusive discounts with our membership card for just{" "}
        {getSubtotalInDollars()} annually. With Dockwa+ you get access to:
      </span>
      <div className="flex flex-col space-y-6">
        <div className="flex items-center space-x-6">
          <i className="icon icon-check text-teal-600" />
          <span>Competitive Dockage Rates</span>
        </div>
        <div className="flex items-center space-x-6">
          <i className="icon icon-check text-teal-600" />
          <span>Unbeatable Fuel Prices</span>
        </div>
        <div className="flex items-center space-x-6">
          <i className="icon icon-check text-teal-600" />
          <span>Exclusive Premium Features: Cruise Plan Tool and more</span>
        </div>
      </div>
      <div className="pt-4">
        <Button
          variant="primary"
          onClick={handleDockwaPlusSignup}
          disabled={
            !dockwaPlusProductInformation || dockwaPlusProductInformation.error
          }
        >
          Sign Up
        </Button>
      </div>
      <DockwaPlusMembershipSignupModal
        isOpen={isSignupModalOpen}
        setIsOpen={setIsSignupModalOpen}
        setUser={setUser}
        paymentMethods={paymentMethods}
        setPaymentMethods={setPaymentMethods}
      />
    </div>
  )

  const handleCancel = () => {
    tracker.trackEvent("dockwa_plus:cancel_membership_pressed")

    return setIsCancelModalOpen(true)
  }

  const handleRestore = () => {
    tracker.trackEvent("dockwa_plus:restore_membership_pressed")

    return restoreMembership()
  }

  const renderCancelButton = (subscription) => {
    if (subscription.cancelAtPeriodEnd) {
      return (
        <>
          <span className="font-semibold text-red-700">
            You&apos;ve cancelled your Dockwa+ membership. You&apos;ll retain
            access to your benefits through{" "}
            {subscription.readableCurrentPeriodEnd}.
          </span>
          <div className="mt-6">
            <Button
              variant="secondary"
              onClick={handleRestore}
              isLoading={restoringMembership}
            >
              {restoringMembership ? "Renewing" : "Renew Dockwa+ Subscription"}
            </Button>
          </div>
        </>
      )
    } else {
      return (
        <>
          <button
            className="text-link w-min whitespace-nowrap text-blue-700"
            onClick={handleCancel}
          >
            Cancel Membership
          </button>
          <span className="text-gray-600">
            (No refunds will be given and you&apos;ll have access to your
            benefits through {subscription.readableCurrentPeriodEnd}.)
          </span>
        </>
      )
    }
  }

  const renderDockwaPlusSubscription = () => (
    <div>
      <div className="flex flex-col justify-between space-y-4 md:flex-row md:space-y-0">
        <div className="flex flex-col items-start space-y-4 md:flex-row md:space-x-4 md:space-y-0">
          <div className="flex w-full justify-center md:block md:w-min">
            <DockwaPlusMembershipCard />
          </div>
          <div className="flex flex-col space-y-2">
            <div className="mb-4 flex items-center space-x-2 md:mb-0">
              <span className="text-gray-600">Status:</span>
              <Badge
                color="teal"
                text={capitalize(user.dockwaPlusSubscription.status)}
              />
            </div>
            <span className="font-semibold">
              {user.dockwaPlusSubscription.currentPrice} / annually + tax
            </span>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-2">
            <span className="text-gray-600">
              Start date: {user.dockwaPlusSubscription.readableStartDate}
            </span>
            <span className="text-gray-600">
              {user.dockwaPlusSubscription.cancelAtPeriodEnd
                ? "End"
                : "Renewal"}{" "}
              date: {user.dockwaPlusSubscription.readableCurrentPeriodEnd}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col pt-6">
        {renderCancelButton(user.dockwaPlusSubscription)}
      </div>
      <CancelDockwaPlusMembershipModal
        user={user}
        isOpen={isCancelModalOpen}
        setIsOpen={setIsCancelModalOpen}
        setUser={setUser}
        subscription={user.dockwaPlusSubscription}
      />
    </div>
  )

  return (
    <div className="flex flex-col space-y-6 pb-8">
      <div className="flex items-center space-x-2">
        <HeadingModalTitle>Dockwa+</HeadingModalTitle>{" "}
        {membershipPageVisited <= 3 && <Badge text="New!" color="teal" />}
      </div>
      {user.dockwaPlusSubscription
        ? renderDockwaPlusSubscription()
        : renderBlankState()}
    </div>
  )
}

DockwaPlusMembershipContent.propTypes = {
  user: PropTypes.shape({
    dockwaPlusSubscription: PropTypes.shape({
      cancelAtPeriodEnd: PropTypes.bool.isRequired,
      currentPrice: PropTypes.string.isRequired,
      readableStartDate: PropTypes.string.isRequired,
      readableCurrentPeriodEnd: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  }).isRequired,
  setUser: PropTypes.func.isRequired,
}

const DockwaPlusMembership = (props) => {
  return (
    <DockwaPlusProvider>
      <DockwaPlusMembershipContent {...props} />
    </DockwaPlusProvider>
  )
}

DockwaPlusMembership.propTypes = {
  user: PropTypes.shape({
    dockwaPlusSubscription: PropTypes.shape({
      cancelAtPeriodEnd: PropTypes.bool.isRequired,
      currentPrice: PropTypes.string.isRequired,
      readableStartDate: PropTypes.string.isRequired,
      readableCurrentPeriodEnd: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  }).isRequired,
  setUser: PropTypes.func.isRequired,
}

export default DockwaPlusMembership
