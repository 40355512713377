import {
  formPostApi,
  formPutApi,
  mutateApi,
  queryApi,
} from "src/utils/api/ApiMethods"

export const resendEmailConfirmation = (email) => {
  return mutateApi({
    url: "/users/confirmations/resend_confirmation_instructions",
    method: "POST",
    data: { user: { email } },
  })
}

export const createAvatarPhoto = async (photo) => {
  const formData = new FormData()
  formData.append("photo", photo)

  return await formPostApi({
    url: "/account/avatar_photo",
    data: formData,
  })
}

export const deleteAvatarPhoto = async (userId) => {
  return await mutateApi({
    url: `/account/avatar_photo/${userId}`,
    method: "DELETE",
  })
}

export const updateAvatarPhoto = async (photo, userId) => {
  const formData = new FormData()
  formData.append("photo", photo)
  return await formPutApi({
    url: `/account/avatar_photo/${userId}`,
    data: formData,
  })
}

export const updatePersonalInfo = async (data) => {
  return await mutateApi({
    url: "/account/settings",
    method: "PUT",
    data: {
      user: {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        phone: data.phone,
      },
    },
  })
}

export const resetPassword = async (data) => {
  return await mutateApi({
    url: "/users",
    method: "PATCH",
    data: {
      user: {
        current_password: data.currentPassword,
        password: data.password,
        password_confirmation: data.passwordConfirmation,
      },
    },
  })
}

export const toggleMarinaNotifications = async (data) => {
  return await mutateApi({
    url: `/account/marinas/${data.marinaId}`,
    method: "PATCH",
    data: {
      id: data.marinaId,
      name: data.notificationName,
      checked: data.checked,
    },
  })
}

export const updateBoatUsMembership = async (data) => {
  return await mutateApi({
    url: "/account/boatus_membership",
    method: "POST",
    data: {
      boatus_id: data.id,
      boatus_last_name: data.lastName,
      boatus_zip: data.zip,
    },
  })
}

export const updateSafeHarborMembership = async (data) => {
  return await mutateApi({
    url: "/account/brewer_membership",
    method: "POST",
    data: {
      brewer_id: data.id,
      first_name: data.firstName,
      last_name: data.lastName,
    },
  })
}

export const removeBoatusMembership = async () => {
  return await mutateApi({
    url: "/account/boatus_membership",
    method: "DELETE",
  })
}
export const removeSafeHarborMembership = async () => {
  return await mutateApi({
    url: "/account/brewer_membership",
    method: "DELETE",
  })
}

export const cancelDockwaPlusMembership = async () => {
  return await mutateApi({
    url: "/account/dockwa_plus/subscriptions",
    method: "DELETE",
  })
}

export const restoreDockwaPlusMembership = async () => {
  return await mutateApi({
    url: "/account/dockwa_plus/subscriptions/restore",
    method: "PATCH",
  })
}

export const queryDockwaPlusProductInformation = async (
  zipCode,
  couponCode
) => {
  const url = new URL(
    "/dockwa_plus/product_information",
    window.location.origin
  )

  if (zipCode) {
    url.searchParams.append("postal_code", zipCode)
  }

  if (couponCode) {
    url.searchParams.append("coupon_code", couponCode)
  }

  return queryApi(url.toString())
}

export const createDockwaPlusMembership = async ({ data }) => {
  return await mutateApi({
    url: "/account/dockwa_plus/subscriptions",
    method: "POST",
    data: {
      payment_method_id: data.paymentMethodId,
      dockwa_plus_version: data.dockwaPlusVersion,
      postal_code: data.postalCode,
      coupon_id: data.couponId,
    },
  })
}
