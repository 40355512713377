import PropTypes from "prop-types"
import React, { createContext, useContext } from "react"
import { useQuery } from "react-query"

import { queryDockwaPlusProductInformation } from "src/api/Account/Settings"

import { useToast } from "src/hooks/use_toast"

import useDockwaPlusForm from "./hooks/useDockwaPlusForm"

export const DockwaPlusContext = createContext()

/**
 * Provider component for DockwaPlus state
 */
export const DockwaPlusProvider = ({ children }) => {
  const showToast = useToast()
  const formState = useDockwaPlusForm()

  const productInfo = useQuery({
    queryKey: [
      "dockwaPlusMembershipProductInformation",
      formState.queryParams.zipCode,
      formState.queryParams.couponCode,
    ],
    queryFn: ({ queryKey }) => {
      const [, zipCode, couponCode] = queryKey
      return queryDockwaPlusProductInformation(zipCode, couponCode)
    },
    refetchOnWindowFocus: false,
    retry: false,
    // Enable automatic refetching when query parameters change
    enabled: true,
    // Ensure React Query detects changes to the queryParams
    keepPreviousData: false,
    onError: (error) => {
      showToast(error.message, { type: "error" })
    },
  })

  const value = {
    formValues: formState.formValues,
    updateField: formState.updateField,
    resetForm: formState.resetForm,
    productInfo: {
      data: productInfo.data,
      isLoading: productInfo.isFetching,
    },
  }

  return (
    <DockwaPlusContext.Provider value={value}>
      {children}
    </DockwaPlusContext.Provider>
  )
}

DockwaPlusProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

/**
 * Custom hook to use the DockwaPlus context
 */
export const useDockwaPlusContext = () => {
  const context = useContext(DockwaPlusContext)
  if (context === undefined) {
    throw new Error(
      "useDockwaPlusContext must be used within a DockwaPlusProvider"
    )
  }
  return context
}
