import { useCallback, useEffect, useRef, useState } from "react"

/**
 * Custom hook to manage DockwaPlus form state with debounced query parameters
 * @returns {Object} Form state and operations
 */
const useDockwaPlusForm = () => {
  const [formValues, setFormValues] = useState({ zipCode: "", couponCode: "" })
  const [queryParams, setQueryParams] = useState({
    zipCode: "",
    couponCode: "",
  })

  const timeoutRef = useRef(null)

  const updateField = useCallback((field, value) => {
    setFormValues((currentValues) => ({
      ...currentValues,
      [field]: value,
    }))
  }, [])

  const resetForm = useCallback(() => {
    const resetValues = { zipCode: "", couponCode: "" }
    setFormValues(resetValues)
    setQueryParams(resetValues)
  }, [])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      setQueryParams(formValues)
    }, 300)

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [formValues])

  return {
    formValues,
    queryParams,
    updateField,
    resetForm,
  }
}

export default useDockwaPlusForm
